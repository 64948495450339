@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: underline;
  text-decoration-color: #cf974f;
  color: black;
  transition: all .2s ease-out;
}

a:hover {
  text-decoration-color: black;
  transition: all .2s ease-out;
}